// Error components must be Client Components
'use client';

import {
  ArrowRightIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import Link from 'next/link';

export default function Error({ error }: { error: Error; reset: () => void }) {
  return (
    <div className="text-center pt-8">
      <ExclamationTriangleIcon className="mx-auto h-16 w-16 text-red-500" />
      <h3 className="mt-6 text-3xl leading-none font-extrabold tracking-tight text-gray-900">
        Something went wrong!
      </h3>
      <p className="mt-4 text-base leading-6 text-gray-500">{error.message}</p>
      <div className="flex items-center justify-center mt-6">
        <Link
          href="/"
          className="flex items-center mx-auto text-base leading-6 font-medium text-bluesmart"
        >
          Go back home <ArrowRightIcon className="h-4 w-4 ml-2" />
        </Link>
      </div>
    </div>
  );
}
